<template>
    <div class="authorization">
    </div>
</template>

<script>
    export default {
        name: 'authorization',
        data () {
            return {
                wechatConfig: {},
                code: '',
                openId: '',
                token: ''
            }
        },
        created () {
            this.getWeChatConfig()
        },
        methods: {
            //  获取微信参数配置
            getWeChatConfig () {
                this.$http({
                    url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.WECHAT_INFO),
                    method: 'GET',
                    params: this.$http.adornParams()
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.wechatConfig = data.sysWechatConfig || ''
                        if (this.wechatConfig && this.wechatConfig.appid) {
                            this.authorizationLogin()
                        }
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            //  微信授权
            authorizationLogin () {
                let wechatConfig = this.$deepClone(this.wechatConfig)
                let baseUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize'
                // let redirectUri = 'http://www.chealth.cn/smes-server/#/authorization'
                // 微信授权重定向地址
                // let redirectUri = `${this.$cons.SERVER_PATH}/#/authorization-back`
                let redirectUri = `${wechatConfig.redirectUri}/back.html`
                let appid = wechatConfig.appid || ''
                let url = encodeURIComponent(redirectUri)
                setTimeout(() => {
                    window.location.href = `${baseUrl}?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=user#index`
                }, 200)
            }
        }
    }
</script>

<style scoped>

</style>